import { Checkbox } from "@blueprintjs/core";
import { CustomHeaderProps } from "ag-grid-react";
import { observer } from "mobx-react";

import styles from "./CheckboxHeaderCell.module.scss";

export interface ICheckboxHeaderCellOwnProps {
  onToggleSelection(): void;
  getIndeterminate(): boolean;
  getChecked(): boolean;
}

interface ICheckboxHeaderCellProps extends CustomHeaderProps, ICheckboxHeaderCellOwnProps {}

const CheckboxHeaderCell = (props: ICheckboxHeaderCellProps) => {
  const { getIndeterminate, getChecked, onToggleSelection } = props;

  return (
    <Checkbox
      onChange={onToggleSelection}
      className={styles.checkboxHeaderCell}
      indeterminate={getIndeterminate()}
      checked={getChecked()}
    />
  );
};

export default observer(CheckboxHeaderCell);
